import React from 'react'
import styled from '../styled'
import breakpoints from '../utils/breakpoint'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.background};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Main = styled.main`
  width: 100%;
  max-width: 640px;
  padding: 20px;
  padding-top: 40px;

  ${breakpoints.phablet} {
    padding-top: 140px;
  }
`

const MainLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Main>{children}</Main>
    </Wrapper>
  )
}

export default MainLayout
