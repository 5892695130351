import React from 'react'
import styled from '../styled'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.background};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loader = styled.div<{ size?: number }>`
  display: inline-block;
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.primary};
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

const Loading: React.FC = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  )
}

export default Loading
