import 'react-app-polyfill/ie11'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import theme from 'theme'
import Routes from './components/Routes'
import MainLayout from './layouts'
import reportWebVitals from './reportWebVitals'
import routes from './routes'
import { globalStyles } from './styled'
import Loading from './layouts/Loading'
import './i18n'

import 'normalize.css'
import './fonts.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />

      <Suspense fallback={<Loading />}>
        <Router>
          <MainLayout>
            <Routes routes={routes} />
          </MainLayout>
        </Router>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
