import config, { Layout } from 'config'

import EnergieLoketLayout from './EnergieLoket'
import MainLayout from './Main'

function getLayout(name?: Layout) {
  switch (name) {
    case 'EnergieLoket':
      return EnergieLoketLayout
    case 'Main':
    default:
      return MainLayout
  }
}

export default getLayout(config.layout)
