import emotionStyled, { CreateStyled, Interpolation } from '@emotion/styled'
import { Theme } from 'theme'
import breakpoints from './utils/breakpoint'

export const globalStyles = (theme: Theme): Interpolation => ({
  '*': {
    boxSizing: 'border-box'
  },
  html: {
    height: '100%'
  },
  body: {
    height: '100%',
    fontFamily: theme.font.family,
    fontSize: theme.font.size,
    lineHeight: theme.font.lineHeight,
    fontWeight: theme.font.lineHeight
  },
  '#root': {
    height: '100%'
  },
  [breakpoints.desktop]: {
    body: {
      fontSize: theme.font.largeSize || theme.font.size
    }
  },
  '::-moz-selection': {
    color: 'white',
    background: theme.accent
  },
  '::selection': {
    color: 'white',
    background: theme.accent
  }
})

const styled = emotionStyled as CreateStyled<Theme>

export default styled
