import React from 'react'
import { ReactComponent as Logo } from '../../assets/svg/energieloket-logo.svg'
import { ReactComponent as Enschede } from '../../assets/svg/energieloket-enschede.svg'
import styled from '../../styled'
import breakpoints from '../../utils/breakpoint'

const LINE_COLOR = 'rgba(255, 255, 255, 0.16)'

const Wrapper = styled.header`
  background: ${({ theme }) => theme.primary};
  width: 100%;
  height: 140px;
  padding: 0 20px;
`

const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  box-shadow: -1px 0 ${LINE_COLOR}, 1px 0 ${LINE_COLOR};
`

const Icon = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 0 ${LINE_COLOR};
  &:nth-of-type(2) {
    box-shadow: none;
  }

  ${breakpoints.desktop} {
    flex-basis: 25%;
    &:nth-of-type(2) {
      box-shadow: 1px 0 ${LINE_COLOR};
    }
  }
`

const Empty = styled.div`
  ${breakpoints.desktop} {
    flex-basis: 50%;
  }
`

const StyledLogo = styled(Logo)`
  height: 32px;
  ${breakpoints.phone} {
    height: 50px;
  }
`

const StyledEnschede = styled(Enschede)`
  height: 24px;
  ${breakpoints.phone} {
    height: 32px;
  }
`

const Header: React.FC = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Icon>
          <StyledLogo />
        </Icon>
        <Icon>
          <StyledEnschede />
        </Icon>
        <Empty />
      </InnerWrapper>
    </Wrapper>
  )
}

export default Header
