import { Theme } from 'theme'

const theme: Theme = {
  background: '#D0D4E3',
  primary: '#272241',
  accent: '#6CC351',
  error: '#d35d6e',
  disabled: '#93b8cb',
  lightGray: '#DDDFE8',
  font: {
    family: "'Titillium Web', sans-serif",
    size: '16px',
    largeSize: '20px',
    weight: 400,
    color: '#272241',
    lineHeight: 1.4
  },
  heading: {
    family: "'Yanone Kaffeesatz', sans-serif",
    weight: 700,
    size: '70px',
    largeSize: '100px',
    transform: 'uppercase',
    lineHeight: 1
  },
  radius: 1,
  breakpoints: [576, 768, 992, 1200]
}

export default theme
