import React from 'react'
import Header from '../components/header/Energieloket'
import styled from '../styled'
import breakpoints from '../utils/breakpoint'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.background};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`

const VerticalLines = styled.div`
  flex: 1;
  box-shadow: -1px 0 rgba(0, 0, 0, 0.16), 1px 0 rgba(0, 0, 0, 0.16);
`

const Main = styled.main`
  ${breakpoints.desktop} {
    display: flex;
  }
`

const EnergieLoketLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <InnerWrapper>
        <VerticalLines>
          <Main>{children}</Main>
        </VerticalLines>
      </InnerWrapper>
    </Wrapper>
  )
}

export default EnergieLoketLayout
