import React from 'react'
import config from 'config'
import { Switch, Route as RouteComponent, Redirect } from 'react-router-dom'
import { Route } from '../routes'

type Props = {
  routes: Route[]
}

const Routes: React.FC<Props> = ({ routes, ...props }) => {
  const homeRoute = config.home
    ? routes.find(r => r.id === config.home)?.path
    : undefined

  return (
    <Switch {...props}>
      {homeRoute && (
        <RouteComponent exact path="/">
          <Redirect to={homeRoute} />
        </RouteComponent>
      )}

      {routes.map(({ id, path, page, ...route }) => (
        <RouteComponent key={id} path={path} component={page} {...route} />
      ))}
    </Switch>
  )
}

export default Routes
