import theme from 'theme'

type Breakpoints = {
  phone: string
  phablet: string
  tablet: string
  desktop: string
}

const breakpoints: Breakpoints = {
  phone: `@media (min-width: ${theme.breakpoints[0]}px)`,
  phablet: `@media (min-width: ${theme.breakpoints[1]}px)`,
  tablet: `@media (min-width: ${theme.breakpoints[2]}px)`,
  desktop: `@media (min-width: ${theme.breakpoints[3]}px)`
}

export default breakpoints
