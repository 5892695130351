import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Fetch from 'i18next-fetch-backend'
import config from 'config'
import merge from 'deepmerge'
import { initReactI18next } from 'react-i18next'

import defaultEN from './i18n-defaults-en.json'
import defaultNL from './i18n-defaults-nl.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const localeDefaults: { [key: string]: any } = {
  en: defaultEN,
  nl: defaultNL
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Fetch)
  .init({
    detection: {
      order: ['querystring', 'navigator']
    },
    backend: {
      loadPath: `/locales/{{lng}}/${config.name}.json`,
      parse: (translation: string) => {
        const obj = JSON.parse(translation)
        // Merge the defaults translation with the used translation.
        return merge(localeDefaults[obj.locale], obj)
      }
    },
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false
    }
  })
