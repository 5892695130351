import React from 'react'
import { RouteProps } from 'react-router-dom'
import config from 'config'

export type Route = Partial<RouteProps> & {
  id: string
  path: string
  page: React.FC<any> // TODO: RouteChildrenProps
}

const optionalRoutes: Route[] = [
  {
    id: 'forgot-password',
    path: '/forgot-password',
    page: React.lazy(() => import('./pages/ForgotPassword')),
    exact: true
  },
  {
    id: 'login',
    path: '/login',
    page: React.lazy(() => import('./pages/Login')),
    exact: true
  },
  {
    id: 'register-email',
    path: '/register-email',
    page: React.lazy(() => import('./pages/RegisterEmail')),
    exact: true
  },
  {
    id: 'register-info',
    path: '/register-info',
    page: React.lazy(() => import('./pages/RegisterInfo')),
    exact: true
  },
  {
    id: 'register-password',
    path: '/register-password',
    page: React.lazy(() => import('./pages/RegisterPassword')),
    exact: true
  },
  {
    id: 'reset-password',
    path: '/reset-password',
    page: React.lazy(() => import('./pages/ResetPassword')),
    exact: true
  },
  {
    id: 'confirm-email',
    path: '/confirm-email',
    page: React.lazy(() => import('./pages/ConfirmEmail')),
    exact: true
  }
]

const routes: Route[] = [
  ...optionalRoutes.filter(route => config.routes.indexOf(route.id) !== -1),
  {
    id: 'success',
    path: '/success',
    page: React.lazy(() => import('./pages/Success')),
    exact: true
  },
  // Should remain at bottom of config, else 'NotFoundPage' will popup everywhere.
  {
    id: 'not-found',
    path: '*',
    page: React.lazy(() => import('./pages/NotFound'))
  }
]

export default routes
